// App.vue
<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  mounted() {
    document.title = '盒子优量互动广告平台-追求创新与质量的广告投放及变现平台'
  }
}
</script>
